<template>
  <div class="turning">
    <!-- 待转正确认人员 -->
    <van-nav-bar :title="$t('turningPositive.turnTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <div class="content">
      <ul style="margin-top:0.32rem;">
        <li v-for="(item, index) in viewList"
            :key="index"
            @click="toViewInfo(item,index)">
          <div class="viewZp">
            <svg-icon style="width:100%;height:100%;"
                      icon-class="default_avator" />
          </div>
          <div style="width: 100%;min-height: 2.25rem;">
            <div class="row1">{{ item.empname }}</div>
            <div class="row2">
              <span>{{item.deptname}}</span>
              <span>{{item.staname}}</span>
            </div>
            <div class="row3">
              <!-- <span class="text1">入</span> -->
              <div class="text1"
                   style="display: inline-block;width: 22px;height: 22px;text-align: center;">{{$t('turningPositive.turnTxt2')}}</div>
              <span class="time1">{{item.entrydate}}</span>
              <div class="text2"
                   style="display: inline-block;width: 22px;height: 22px;text-align: center;">{{$t('turningPositive.turnTxt3')}}</div>
              <span class="time2">{{item.confirmdate}}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getZzCheckList } from '@api/wxzp.js'
const userInfo = localStorage.userInfo? JSON.parse(localStorage.userInfo) : {}
import { Dialog, Toast } from 'vant'
export default {
  data () {
    return {
      userInfo,
      viewList: [],


    };
  },
  created () {
    this.getdate()
  },
  methods: {
    onClickLeft () {
      this.$router.push("/home");
    },
    onClickRight () {

    },
    toViewInfo (ite, idx) {
      this.$router.push({ path: "/turningPositiveInfo" });
      window.sessionStorage.setItem('positiveInfoData', JSON.stringify(ite))
    },
    async getdate () {
      let res = await getZzCheckList({
        empid: this.userInfo.empid
      })
      // console.log(res.data)
      this.viewList = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.turning {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .content {
    height: calc(100% - 92px);
    overflow: auto;
    .van-calendar {
      margin-bottom: 24px;
    }
    ul {
      li {
        position: relative;
        padding: 20px 30px;
        padding-bottom: 0;
        // height: 378px;
        background: #fff;
        box-sizing: border-box;
        margin-bottom: 24px;
        .viewZp {
          position: absolute;
          top: 38px;
          right: 30px;
          width: 1.8rem;
          height: 1.8rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .row1 {
          height: 40px;
          line-height: 40px;
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          margin-bottom: 20px;
          line-height: 1;
        }
        .row2 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #666;
          margin-bottom: 10px;
          // line-height: 1;
          span:first-child {
            padding-right: 14px;
            border-right: 1px solid #999;
          }
          span:last-child {
            margin-left: 10px;
          }
        }
        .row3 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #666;
          padding-bottom: 10px;
          .text1,
          .text2 {
            padding: 0 4px;
            border: 1px solid #2b8df0;
            background: #2b8df0;
            border-radius: 50%;
            color: #fff;
          }
          .text2 {
            margin-left: 14px;
          }
          .time1 {
            padding-left: 10px;
            padding-right: 14px;
            border-right: 1px solid #999;
          }
          .time2 {
            padding: 0 10px;
          }
        }
        .row4 {
          display: flex;
          justify-content: space-between;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-bottom: 30px;
          line-height: 1;
          .row4_left {
            color: #2b8df0;
            span {
              margin-right: 10px;
            }
            .svg-icon {
              font-size: 36px;
              margin: 0 5px;
            }
          }
          .row4_right {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
          }
        }
        .row5 {
          display: flex;
          border-top: 1px solid #ccc;
          padding: 15px;
          span {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          span:first-child {
            border-right: 1px solid #cccccc;
          }
        }
      }
    }
  }
}
.limitclick {
  color: #ccc !important;
}
</style>
